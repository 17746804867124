import React from "react";
import { Link } from "gatsby";

import Layout from "components/layout";
import SEO from "components/seo";
import Style from "styles/404.module.scss";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" path="404" />
    <div className={Style.con}>
      <h2 className={Style.con__errorMsg}>お探しのページが見つかりません</h2>
      <p className={Style.con__text}>
        お探しのページは削除されたか、一時的にアクセスができない状態にある可能性がございます
      </p>
      <Link to="/" className={Style.con__topLink}>
        トップページへ戻る
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
